import { useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../UI/Button/Button";
import styles from "./Payment.module.css";
import {
  LBL__DISCOUNT,
  LBL__CHECKOUT,
  LBL__SUB_TOTAL,
  LBL__TOTAL_PAYABLE,
} from "../../../consts/TextConstants";
import {
  URL__STRIPE_SUCCESS_REDIRECT,
  URL__STRIPE_FAILURE_REDIRECT,
} from "../../../consts/APIConstants";
import { useCheckoutCartMutation } from "./apis/cartApi-slice";
import {
  setStripeRedirectUrls,
  removeStripeRedirectUrls,
} from "../../../utils/authentication";
import { absolutePaths } from "../../../consts/PathConstants";
import CouponCode from "../CouponCode/CouponCode";
import MessageDialog from "../../UI/Modal/MessageDialog";

const Payment = () => {
  const [promoData, setPromoData] = useState({
    promoCode: "",
    isApplied: false,
    totalPayable: 0,
    discount: 0,
  });
  const [message, setMessage] = useState({ show: false });

  const { totalAmount, cartItems, selectedEntity } = useSelector(
    (state) => state.cart
  );

  const isCartEmpty = cartItems.length === 0;

  const [checkoutCart, { isLoading: loadingCheckoutCart }] =
    useCheckoutCartMutation();

  const handleCheckout = async () => {
    try {
      const requestBody = {
        entity_id: selectedEntity?.entityId,
        coupon_code: promoData?.promoCode,
        success_url: URL__STRIPE_SUCCESS_REDIRECT,
        cancel_url: URL__STRIPE_FAILURE_REDIRECT,
      };
      setStripeRedirectUrls(absolutePaths.COMPLIANCE, absolutePaths.SHOP, {
        complianceEntity: selectedEntity,
      });
      const response = await checkoutCart(requestBody).unwrap();
      const reditectUrl = response?.url;
      window.location.href = reditectUrl;
    } catch (error) {
      console.log(error);
      removeStripeRedirectUrls();
      setMessage({
        show: true,
        isError: true,
        message: error?.message || error?.data?.message,
        title: "Checkout Error",
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.top_container}>
        <div className={styles.card}>
          <div className={styles.price}>
            <p>{LBL__SUB_TOTAL}</p>
            <p>{isCartEmpty ? "$0.00" : `$${totalAmount?.toFixed(2)}`}</p>
          </div>

          {!isCartEmpty && promoData.isApplied && (
            <div className={styles.discount}>
              <div className={styles.discount_type_cont}>
                <p>{LBL__DISCOUNT} </p>
                <p className={styles.discount_type}>{promoData.promoCode}</p>
              </div>
              <p>
                {isCartEmpty
                  ? "-"
                  : `-$${promoData?.discount?.toFixed(2) || 0}`}
              </p>
            </div>
          )}
        </div>

        {!isCartEmpty && (
          <CouponCode onChange={setPromoData} actualPrice={totalAmount} />
        )}

        <div className={styles.total_bill}>
          {LBL__TOTAL_PAYABLE}
          <p className={styles.total_price}>
            {isCartEmpty
              ? "$0.00"
              : `$${
                  promoData.isApplied
                    ? promoData.totalPayable
                    : totalAmount?.toFixed(2)
                }`}
          </p>
        </div>
      </div>

      <div>
        <Button
          buttonstyle="default-blue"
          className={`${styles.btn_pay} ${isCartEmpty ? styles.disabled : ""}`}
          onClick={handleCheckout}
          disabled={isCartEmpty || loadingCheckoutCart}
          isLoading={loadingCheckoutCart}
        >
          {LBL__CHECKOUT}
        </Button>
      </div>
      {message.show && (
        <MessageDialog
          isError={message.isError}
          title={message.title}
          message={message.message}
          onClose={() => setMessage({show:false})}
        />
      )}
    </div>
  );
};

export default Payment;
