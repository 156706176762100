import styles from "./SubscriptionCard.module.css";
import ServiceCardWrapper from "./ServiceCardWrapper.jsx";
import { subscriptionTabs } from "../../../../consts/TextConstants.jsx";
import ArrowRight from "/assets/common/Arrow_Gray.svg";

const SubscriptionCard = ({ activeTab, subscription, onClick }) => {
  // Define the header based on the activeTab prop
  const header =
    activeTab === subscriptionTabs.COMPLIANCE ? (
      <div className={styles.header_style}>
        <div className={styles.header_first_half}>
          <h2 className={styles.header_title}>{subscription?.name}</h2>
          <p className={styles.Tax_header_shortDescription}>
            {subscription?.shortDescription}
          </p>
        </div>
      </div>
    ) : (
      <div className={styles.header_services_style}>
        <h2 className={styles.header_title}>{subscription?.name}</h2>
        <p className={styles.header_shortDescription}>
          {subscription?.shortDescription}
        </p>
      </div>
    );

  // Footer section containing the price and a button to add a service
  const footer = (
    <div className={styles.footer_style}>
      <p className={styles.price}>
        {subscription?.price > 0 ? `$${subscription?.price}` : ""}
      </p>
      <div className={styles.forward_cont}>
        <img src={ArrowRight} className={styles.forward_img} />
      </div>
    </div>
  );

  return (
    <ServiceCardWrapper
      header={header}
      footer={footer}
      className={styles.servicecard_container}
      onClick={onClick}
    />
  );
};

export default SubscriptionCard;
