import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceList: [],
  selectedService: null,
};

const subscriptionsServicesSlice = createSlice({
  name: "subscriptionsServices",
  initialState,
  reducers: {
    setServiceList: (state, action) => {
      const services = action.payload?.services;
      let serviceList = [];
      services?.forEach((service) => {
        serviceList.push({
          name: service?.service_name,
          shortDescription: service?.service_description,
          price: service?.service_price,
          serviceId: service?.service_id,
          serviceCategory: service?.service_category,
          serviceInputs: service?.service_inputs?.inputs || [],
        });
      });
      state.serviceList = serviceList;
    },
    setSelectedService: (state, action) => {
      const serviceData = action.payload?.data || {};
      // Update state with the correct selected service data
      let serviceInputs = [];
      let shouldFetchTRCYears = false;
      serviceData?.service_inputs?.inputs?.forEach((input) => {
        if (input.value === "trc_years") {
          shouldFetchTRCYears = true;
        }
        serviceInputs.push({
          label: input.label_name || input.field_name,
          fieldName: input.field_name,
          inputType: input.input_type,
          fieldValue: input.value,
        });
      });
      state.selectedService = {
        serviceId: serviceData?.service_id,
        serviceName: serviceData?.service_name,
        serviceDescription: serviceData?.service_description,
        servicePrice: serviceData?.service_price,
        serviceCategory: serviceData?.service_category,
        serviceInputs,
        shouldFetchTRCYears,
      };
    },
  },
});

export const subscriptionsServicesActions = subscriptionsServicesSlice.actions;

export default subscriptionsServicesSlice.reducer;
