import {
  METHOD_GET,
  URL__SET_COUPOUN_CODE,
} from "../../../../consts/APIConstants";
import { apiSlice } from "../../../../store/api-slice";
import { cartActions } from "../../Cart/reducers/cart-slice";

const couponCodeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
   setCouponCode: builder.mutation({
      query: (data) => {
        return {
          url: URL__SET_COUPOUN_CODE,
          method: METHOD_GET,
          params: data,
        };
      },
    }),
  }),
});

export const { useSetCouponCodeMutation } = couponCodeApiSlice;
