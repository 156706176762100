export const isOTP = (value) => {
  return /^\d+$/.test(value) && value.length == 4;
};

export const isNotEmpty = (value) =>  value?.toString()?.trim() != "";

export const isGreaterThanValue = (value, minValue) => value > minValue;

export const isLessThanValue = (value, maxValue) => value < maxValue;

export const isLessThanOrEqualToValue = (value, maxValue) => value <= maxValue;
export const isGreaterThanOrEqualToValue = (value, minValue) =>
  value >= minValue;

export const isBetweenRange = (value, min, max) =>
  isGreaterThanOrEqualToValue(value, min) &&
  isLessThanOrEqualToValue(value, max);

export const hasMinLength = (value, minLength) => value.length >= minLength;

export const hasMaxLength = (value, maxLength) => value.length <= maxLength;

export const isEqualToOtherValue = (value, otherValue) => value == otherValue;

export const isEmail = (value) =>
  /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(value);

export const isPassword = (value) =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/.test(value);

// export const isPhoneNumber = (value) => /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value);
// export const isPhoneNumber = (value) =>
//   /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
//     value
//   );
export const isPhoneNumber = (value) =>
  /^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/.test(value);

export const isPhoneNumberWithoutCountryCode =  (value) =>
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
    value
  );
// export const isPhoneNumber = (value) => /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/.test(value);
// export const isPhoneNumber = (value) =>
//   /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/.test(
//     value
//   );

export const isNumeric = (value) => !isNaN(value);

export const isAlphaNumeric = (value) => /^[a-zA-Z0-9]+$/.test(value);

export const isValid = (value) => value != undefined && value != null && value.toString() != "";

export const isNonUSCountry = (value) => value != "United States";

export const isEINFormat = (value) => /^[0-9]{2}-[0-9]{7}$/.test(value);

export const isACHFormat = (value) => /^[0-9]{9}$/.test(value);

export const isAccountNumber = (value) => /^[0-9]{6,17}$/.test(value);

export const isZipCode = (value) => /^(?!.*--)(?=.{3,10}$)[A-Za-z0-9]+(?:[- ][A-Za-z0-9]+)*$/.test(value);