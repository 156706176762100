import { useState } from "react";
import styles from "./SubscriptionsPage.module.css";
import TabListItem from "../../UI/Tab/TabListItem";
import TabContent from "../../UI/Tab/TabContent";
import Wrapper from "../../UI/Wrapper/Wrapper";
import SearchBar from "../../UI/Input/SearchBar";
import FixedServices from "./FixedServices.jsx";
import GetServicesListPage from "./GetServicesListPage.jsx";
import { tabStyles } from "../../../consts/Constants";
import { subscriptionTabs } from "../../../consts/TextConstants.jsx";
import TaxComplianceListPage from "./TaxComplianceListPage.jsx";

const SubscriptionsPage = () => {
  const [activeTab, setActiveTab] = useState(subscriptionTabs.COMPLIANCE);
  const [searchValue, setSearchValue] = useState("");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  return (
    <Wrapper title="Shop">
      <div className={styles.container}>
        <div className={styles.tabs__list}>
          {Object.values(subscriptionTabs).map((tab) => (
            <TabListItem
              key={tab}
              onClick={() => handleTabChange(tab)}
              activeTab={activeTab === tab}
              tabStyle={tabStyles.UNDERLINED}
            >
              {tab?.toUpperCase()}
            </TabListItem>
          ))}
        </div>

        <SearchBar
          id="input-search"
          placeholder={`Search ${activeTab}...`}
          onChange={handleSearchChange}
          inputstyle={styles.search_bar}
        />

        <div className={styles.tab__content}>
          <TabContent id={subscriptionTabs.COMPLIANCE} activeTab={activeTab}>
            <TaxComplianceListPage
              activeTab={activeTab}
              searchValue={searchValue}
            />
          </TabContent>

          <TabContent id={subscriptionTabs.SERVICES} activeTab={activeTab}>
            <div className={styles.tab__section}>
              {/* <FixedServices /> */}
              <GetServicesListPage
                searchValue={searchValue}
                activeTab={activeTab}
              />
            </div>
          </TabContent>
        </div>
      </div>
    </Wrapper>
  );
};

export default SubscriptionsPage;
