import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetServiceByIdQuery } from "../apis/subscriptionsServicesApi-slice";
import OverlayRegular from "../../../UI/Modal/OverlayRegular";
import styles from "./AddServiceOverlay.module.css";
import ServiceForm from "./ServiceForm";

const AddServiceOverlay = ({ serviceId, onClose }) => {
  // Fetch the service data using the API hook
  const { data, error, isLoading } = useGetServiceByIdQuery({
    service_id: serviceId,
  });

  // Get the selected service from Redux state
  const { selectedService } = useSelector(
    (state) => state.subscriptionsServices
  );

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <OverlayRegular onClose={onClose}>
        <div className={styles.container}>
          <div className={styles.header_container}>
            <div className={styles.title_container}>
              <div className={styles.title}>{selectedService?.serviceName}</div>
              <p className={styles.para}>
                {selectedService?.serviceDescription}
              </p>
            </div>
            {selectedService?.servicePrice > 0 && (
              <div className={styles.price_one}>
                ${selectedService?.servicePrice}
              </div>
            )}
          </div>

          <div className={styles.body_container}>
            <ServiceForm onClose={onClose} />
          </div>
        </div>
      </OverlayRegular>
    </>
  );
};

export default AddServiceOverlay;
