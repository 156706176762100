import { useEffect, useRef, useState } from "react";
import styles from "./Calendar.module.css";
import OverlayRegular from "../Modal/OverlayRegular";
import { getStringFromDateInFormat } from "../../../utils/common-utils";
import calendarIcon from "/assets/common/Calendar.svg";
import arrowDownIcon from "/assets/common/ArrowDown_Filled.svg";
import CalendarView from "./CalendarView";
import { BTN__DONE } from "../../../consts/TextConstants";
import Button from "../Button/Button";

const Calendar = ({
  selectedDate,
  id,
  className,
  startDate = new Date("01/01/1800"),
  endDate = new Date(),
  showCalenderIcon = true,
  showArrowDownIcon = false,
  arrowStyle,
  datePickerStyle,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (event) => {
      if (isOpen && ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <div className={className} id={id}>
      <button
        className={`${styles.date_picker} ${datePickerStyle}`}
        onClick={() => setIsOpen(true)}
        type="button"
      >
        {getStringFromDateInFormat(selectedDate, "mmm dd, yyyy")}
        {showCalenderIcon && <img src={calendarIcon} alt="" />}
        {showArrowDownIcon && <img src={arrowDownIcon} alt="" className={arrowStyle}/>}
      </button>
      {isOpen && (
        <OverlayRegular blurValue="0.1" className={styles.datepicker_overlay} showCloseIcon={false}>
          <div className={styles.container} ref={ref}>
            <div className={styles.topBar}>
              <div className={styles.title}> Select Date</div>
              <div className={styles.selectedDate}>
                {getStringFromDateInFormat(selectedDate)}
              </div>
            </div>
            <CalendarView
              selectedDate={selectedDate || new Date()}
              className={styles.overlay}
              startDate={startDate}
              endDate={endDate}
              onSelectDay={() => setIsOpen(false)}
              {...props}
            />
            <div className={styles.footer}>
              <Button 
                className={styles.btn__done}
                onClick={() => setIsOpen(false)}
              >
                {BTN__DONE}
              </Button>
            </div>
          </div>
        </OverlayRegular>
      )}
    </div>
  );
};

export default Calendar;
