import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useGetEntitiesQuery } from "../../Entities/apis/entityApi-slice";
import styles from "./ServiceForm.module.css";
import EditInputItem from "../../Edit/EditInputItem";
import {
  URL__STRIPE_SUCCESS_REDIRECT,
  URL__STRIPE_FAILURE_REDIRECT,
  METHOD_GET,
} from "../../../../consts/APIConstants";
import { absolutePaths } from "../../../../consts/PathConstants";
import { setStripeRedirectUrls } from "../../../../utils/authentication";
import {
  useCreateServiceSubscriptionMutation,
  useCreateQuoteServiceMutation,
} from "../apis/subscriptionsServicesApi-slice";
import Button from "../../../UI/Button/Button";
import {
  useAddCartItemMutation,
  useLazyGetAvailableCartEntityListQuery,
} from "../../Cart/apis/cartApi-slice";
import arrowRightWhite from "/assets/common/ArrowRight_White.svg";
import SubscriptionInputSelector from "../ui-subscriptions/SubscriptionInputSelector";
import { useLazyGetTRCYearsQuery } from "../../Services/apis/serviceApi-slice";
import GetQuoteSuccessMessage from "./GetQuoteSuccessMessage";
import { functions } from "../../../../lib/appwrite";
import MessageDialog from "../../../UI/Modal/MessageDialog";
import { BTN__RETRY, BTN__CLOSE } from "../../../../consts/TextConstants";

const ServiceForm = ({ onClose }) => {
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [dynamicInputValues, setDynamicInputValues] = useState({});
  const [trcYears, setTrcYears] = useState([]);
  const [showGetQuoteMessage, setShowGetQuoteMessage] = useState(false);
  const [message, setMessage] = useState({ show: false });

  const [showEntityError, setShowEntityError] = useState(false);
  const [showDynamicInputError, setShowDynamicInputError] = useState(false);

  const { entities } = useSelector((state) => state.entity);
  const { selectedService } = useSelector(
    (state) => state.subscriptionsServices
  );
  const { userDetails } = useSelector((state) => state.auth);

  const { isError: isEntitiesError } = useGetEntitiesQuery();

  const [createServiceSubscription, { isLoading: loadingPayment }] =
    useCreateServiceSubscriptionMutation();

  const [addCartItem, { isLoading: loadingAddCartItem }] =
    useAddCartItemMutation();

  const [getAvailableCartEntityList, { isLoading: loadingCartEntityList }] =
    useLazyGetAvailableCartEntityListQuery();

  const [
    getTrcYears,
    { isFetching: isTrcYearsLoading, isError: isTrcYearsError },
  ] = useLazyGetTRCYearsQuery();

  const [createQuoteService, { isLoading: loadingQuoteService }] =
    useCreateQuoteServiceMutation();

  const handleInputChange = (fieldName, value) => {
    setDynamicInputValues({ ...dynamicInputValues, [fieldName]: value });
  };

  const entityOptions = useMemo(() => {
    if (!entities || entities.length === 0) return {};

    const countryFilter = (entity) => entity.country === "United States";

    return entities.filter(countryFilter)?.reduce((acc, entity) => {
      acc[entity.entityName] = entity.entityId;
      return acc;
    }, {});
  }, [entities]);

  const isGetQuote = useMemo(
    () => selectedService?.servicePrice === 0,
    [selectedService]
  );

  const handleEntityChange = async (entity) => {
    setSelectedEntity(entity);
    setDynamicInputValues({});
    setShowEntityError(false);
    if (selectedService?.shouldFetchTRCYears) {
      try {
        const response = await getTrcYears({
          entityId: entityOptions[entity],
        }).unwrap();
        setTrcYears(response?.years);
      } catch (error) {
        console.error("Error fetching TRC years:", error);
      }
    }
  };

  const validateForm = () => {
    let isDynamicInputError = false;
    selectedService?.serviceInputs?.forEach((input) => {
      if (!dynamicInputValues[input.fieldName]) {
        isDynamicInputError = true;
      }
    });

    if (!selectedEntity || isDynamicInputError) {
      setShowEntityError(!selectedEntity);

      setShowDynamicInputError(isDynamicInputError);
      return false;
    }
    return true;
  };

  const handlePayment = async () => {
    if (!validateForm()) {
      return;
    }
    const postData = {
      product_id: selectedService?.serviceId,
      entity_id: entityOptions[selectedEntity],
      subscription_inputs: {
        ...dynamicInputValues,
      },
    };
    if (isGetQuote) {
      // try {
      //   await createQuoteService(postData).unwrap();
      //   setShowGetQuoteMessage(true);
      // } catch (err) {}

      try {
        const response = await functions.createExecution(
          import.meta.env.VITE_APPWRITE_SUPPORT_EMAIL_ID,
          JSON.stringify({
            user_email: userDetails?.userEmail,
            emailType: "quote-email",
            entity_id: entityOptions[selectedEntity],
            product_id: selectedService?.serviceId,
          }),
          false,
          "/",
          METHOD_GET,
          { "Content-Type": "application/json" }
        );
        setShowGetQuoteMessage(true);
      } catch (err) {
        console.log("err", err);
      }
    } else {
      try {
        const requestData = {
          ...postData,
          success_url: URL__STRIPE_SUCCESS_REDIRECT,
          cancel_url: URL__STRIPE_FAILURE_REDIRECT,
          // coupon_code: "WELCOME20", // Optional Field
        };
        setStripeRedirectUrls(absolutePaths.SERVICES, absolutePaths.SERVICES);
        const response = await createServiceSubscription(requestData).unwrap();

        const reditectUrl = response?.url;
        window.location.href = reditectUrl;
      } catch (error) {
        console.error("Error during payment:", error);
      }
    }
  };

  const handleAddToCart = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const requestData = {
        product_id: selectedService?.serviceId,
        entity_id: entityOptions[selectedEntity],
        subscription_inputs: {
          ...dynamicInputValues,
        },
      };
      const response = await addCartItem(requestData).unwrap();
      await getAvailableCartEntityList().unwrap();
      setMessage({
        show: true,
        isError: false,
        title: "Success",
        message: "Added to cart successfully!!",
        closeOnConfirm: true,
      });    } catch (error) {
      setMessage({
        show: true,
        isError: true,
        title: "Error!",
        message: "Failed to add to cart. Please try again.",
        closeOnConfirm: false,
      });    }
  };

  const handleCloseGetQuoteMessage = () => {
    setShowGetQuoteMessage(false);
    onClose();
  };

  const handleCloseMessage = () => {
    setMessage({ show: false });
    if (message.closeOnConfirm) {
      onClose();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <EditInputItem
          name="entity"
          type="dropdown"
          labelTitle="Entity"
          className={styles.entity_form}
          options={Object.keys(entityOptions)}
          value={selectedEntity}
          onSelect={handleEntityChange}
          showError={showEntityError}
          errorMessage="Please select an entity"
        />

        {selectedService?.serviceInputs?.map((input) => (
          <SubscriptionInputSelector
            key={`${input.fieldName}-${input.inputType}`}
            inputDetails={input}
            entityType={
              entities.find(
                (entity) => entity.entityId === entityOptions[selectedEntity]
              )?.entityType
            }
            onChange={(value) => handleInputChange(input.fieldName, value)}
            value={dynamicInputValues[input.fieldName] || ""}
            className={styles.tax_wid}
            trcYears={trcYears}
            showError={
              showDynamicInputError && !dynamicInputValues[input.fieldName]
            }
            errorMessage="Please select a value"
          />
        ))}
      </div>

      <div className={styles.footer}>
        {!isGetQuote && (
          <Button
            buttonstyle="default-blue-outline"
            className={styles.btn_add_to_cart}
            onClick={handleAddToCart}
            disabled={loadingPayment}
            isLoading={loadingAddCartItem}
          >
            Add to cart
          </Button>
        )}
        <Button
          buttonstyle="default-blue"
          className={styles.btn__submit}
          onClick={handlePayment}
          disabled={loadingAddCartItem}
          loading={loadingPayment}
        >
          {isGetQuote ? "REQUEST QUOTE" : "PAY"}
          <img src={arrowRightWhite} className={styles.right_icon} />
        </Button>
      </div>
      {showGetQuoteMessage && (
        <GetQuoteSuccessMessage onClose={handleCloseGetQuoteMessage} />
      )}
      {message.show && (
        <MessageDialog
          isError={message?.isError}
          title={message?.title}
          message={message?.message}
          buttonTitle={message?.isError ? BTN__RETRY : BTN__CLOSE}
          onClose={handleCloseMessage}
        />
      )}
    </div>
  );
};

export default ServiceForm;
