import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  convertNumbersToShorts,
  getCountriesList,
  convertISODateToStr,
  countriesLookup,
  convertShortsToNumbers,
} from "../../../../utils/common-utils";

const initialState = {
  authorizedShares: 0,
  issuedShares: 0,
  nonIssuedShares: 0,
  stakesData: {
    Shares: [],
    Safes: [],
    Options: [],
  },
};

const capTableSlice = createSlice({
  name: "capTable",
  initialState,
  reducers: {
    handleStakesData(state, action) {
      const { Shares, Safes, Options } = action.payload;
      let shares = [];
      // let issuedShares = 0;
      const countriesList = getCountriesList();
      // Shares.forEach((item) => {
      //   issuedShares += parseFloat(item.shareholder_no_of_shares);
      // });

      Shares.forEach((item) => {
        shares.push({
          certificate: "file",
          shareHolder: item.shareholder_name,
          taxResidency: countriesLookup(item.shareholder_country)?.key,
          shareClass: item.share_type,
          issuedDate: convertISODateToStr(item.shareholder_issued_date),
          noOfShares: convertNumbersToShorts(item.shareholder_no_of_shares),
          // ownership: (
          //   (item.shareholder_no_of_shares * 100) /
          //   issuedShares
          // ).toFixed(1),
          ownership: item.shareholder_ownership_perc,
          parValue: 1 * parseFloat(item.share_par_value),
        });
      });

      let sAFEs = [];
      Safes.forEach((item) => {
        sAFEs.push({
          shareHolder: item.shareholder_name,
          issuedDate: convertISODateToStr(item.shareholder_issued_date),
          type: item.share_type,
          amount: convertNumbersToShorts(item.share_safe_amount),
        });
      });

      let stockOptionPools = [];
      Options.forEach((item) => {
        stockOptionPools.push({
          shareHolder: item.shareholder_name,
          type: item.share_type,
          grantDate: convertISODateToStr(item.shareholder_issued_date),
          noOfUnits: convertNumbersToShorts(item.shareholder_no_of_shares),
          strikePrice: item.share_strike_price,
        });
      });
      state.stakesData = {
        Shares: shares,
        Safes: sAFEs,
        Options: stockOptionPools,
      };
      // state.issuedShares = issuedShares;
      // state.nonIssuedShares = state.authorizedShares - issuedShares;
    },

    setAuthorizedShares(state, action) {
      state.authorizedShares = parseFloat(action.payload || 0);
      state.nonIssuedShares = action.payload
        ? parseFloat(action.payload) - state.issuedShares
        : 0;
    },

    setIssuedShares(state, action) {
      state.issuedShares = parseFloat(action.payload || 0);
      state.nonIssuedShares = action.payload
        ? state.authorizedShares - parseFloat(action.payload)
        : 0;
    },

    resetCaptableData(state) {
      // state.issuedShares = 0;
      state.nonIssuedShares = state.authorizedShares;
      state.stakesData = {
        Shares: [],
        Safes: [],
        Options: [],
      };
    },
  },
});

export const capTableActions = capTableSlice.actions;

export default capTableSlice.reducer;

export const entitySelector = (state) => state.entity.selectedEntity;
export const capTableSelector = (state) => state.capTable;

export const calculateCapTableData = createSelector(
  [entitySelector, capTableSelector],
  (entity, capTable) => {
    const { authorizedShares, issuedShares } = entity.registrationInfo;
    const { Shares } = capTable.stakesData;
    const nonIssuedShares = authorizedShares - issuedShares;
    let totalNoOfShares = 0;
    let updatedShares = Shares?.map((share) => {
      totalNoOfShares += convertShortsToNumbers(share.noOfShares);
      return {
        ...share,
        ownership:
          Number(issuedShares) > 0
            ? ((convertShortsToNumbers(share.noOfShares) * 100) /
                parseFloat(issuedShares))?.toFixed(2)
            : share.ownership,
      };
    });

    return {
      authorizedShares,
      issuedShares,
      nonIssuedShares,
      totalNoOfShares,
      stakesData: {
        ...capTable.stakesData,
        Shares: updatedShares,
      },
    };
  }
);
