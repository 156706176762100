import { useNavigate } from "react-router-dom";
import styles from "./StripeFailurePage.module.css";
import Button from "../UI/Button/Button";
import { removeStripeRedirectUrls, getStripeRedirectUrls } from "../../utils/authentication";

const StripeFailurePage = () => {
  const navigate = useNavigate();
  const { failureUrl } = getStripeRedirectUrls(); 

  const handleGoToServices = () => {
    removeStripeRedirectUrls();
    navigate(failureUrl);
  };

  return (
    <div className={styles.container}>
      <p>Payment Failed</p>
      <Button onClick={handleGoToServices} buttonstyle="default-blue">Go back to Merze</Button>
    </div>
  );
};

export default StripeFailurePage;
