import { PLACEHOLDER_ATTACH_FILE } from "../../../consts/TextConstants";
import styles from "./EditInputItem.module.css";
import FileInput from "../../UI/Input/FileInput";
import AutoComplete from "../../UI/AutoComplete";
import Dropdown from "../../UI/Dropdown/Dropdown";
import Calendar from "../../UI/DatePicker/Calendar";
import InputCustom from "../../UI/Input/InputCustom";
import InputRadio from "../../UI/Input/InputRadio";
import infoIcon from "/assets/common/MoreInfo.svg";
import useHover from "../../../hooks/use-hover";
import MultiSelectionDropdown from "../../UI/Dropdown/MultiSelectionDropdown";
import SwitchInput from "../../UI/Input/SwitchInput";
const EditInputItem = ({
  children,
  inputcontainerstyle,
  labelTitle,
  name,
  type,
  options,
  showError,
  errorMessage,
  moreinfotext,
  datePickerStyle,
  className,
  ...props
}) => {
  let inputItem = <></>;
  switch (type) {
    case "file":
      inputItem = (
        <FileInput
          key={name}
          placeHolder={PLACEHOLDER_ATTACH_FILE}
          onChange={props.onChange}
        />
      );
      break;
    case "autocomplete":
      inputItem = (
        <AutoComplete
          id={name}
          label={labelTitle}
          onSelect={props.onChange}
          autocompleteSize={props.className}
          listSize={styles.input_autocomplete_list}
          {...props}
        />
      );
      break;
    case "dropdown":
      inputItem = (
        <Dropdown
          placeholder=""
          options={options}
          selected={props.value}
          onSelect={props.onChange}
          id={name}
          dropdownSize={`${styles.input_dropdown} ${props.className}`}
          dropdownListSize={styles.input_dropdown_list}
          {...props}
        />
      );
      break;

    case "datepicker":
      inputItem = (
        <Calendar
          onChange={props.onChange}
          id={name}
          selectedDate={props.value}
          {...props}
          datePickerStyle={props.datePickerStyle}
        />
      );
      break;

    case "radio":
      inputItem = (
        <div className={styles.radio_options} key={name}>
          {options.map((item) => {
            return (
              <InputRadio
                key={`radio-${item}`}
                selected={props.value == item}
                label={item}
                onSelect={() => props.onChange(item)}
                labelStyle={styles.radio}
              />
            );
          })}
        </div>
      );
      break;
    case "switch":
      inputItem = <SwitchInput {...props} />;
      break;

    case "label":
      inputItem = <div className={styles.groupTitle}>{labelTitle}</div>;
      break;
    case "multiselect":
      inputItem = (
        <MultiSelectionDropdown
          options={options}
          selectedOptions={props.value}
          onSelect={props.onChange}
          {...props}
        />
      );
      break;
    case "textarea":
      inputItem = (
        <textarea
          className={`${styles.textarea_container} ${props.className}`}
          value={props.value}       // Add value prop
          onChange={props.onChange} // Add onChange prop
          name={props.name}
        ></textarea>
      );
      break;
    case "custom":
      inputItem = <>{children}</>;
      break;
    default:
      inputItem = <InputCustom key={name} type={type} {...props} />;
      break;
  }
  const [isHovered, hoverProps] = useHover();

  return (
    <div className={`${styles.input_item} ${inputcontainerstyle}`}>
      {type != "label" && (
        <div className={styles.label}>
          {labelTitle}
          {moreinfotext && (
            <div className={styles.more_info} {...hoverProps}>
              <img src={infoIcon} alt="" />
              {isHovered && (
                <div className={styles.more_info_tooltip}>{moreinfotext}</div>
              )}
            </div>
          )}
        </div>
      )}
      {inputItem}
      {showError && <div className={styles.error}>{errorMessage}</div>}
    </div>
  );
};

export default EditInputItem;
