//this is w 100%
import { useEffect } from "react";
import OverlayRegular from "../../UI/Modal/OverlayRegular";
import CartBody from "./CartBody";
import CartHeader from "./CartHeader";
import styles from "./CartPage.module.css";
import { useDispatch } from "react-redux";
import { cartActions } from "./reducers/cart-slice";

const CartPage = ({ onClose }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleClose = () => {
    dispatch(cartActions.setSelectedEntity(null));
    onClose();
  };
  return (
    <OverlayRegular onClose={handleClose}>
      <div className={styles.container}>
        <CartHeader />
        <CartBody />
      </div>
    </OverlayRegular>
  );
};

export default CartPage;
