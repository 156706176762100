import { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useGetEntitiesQuery } from "../../Entities/apis/entityApi-slice";
import EditInputItem from "../../Edit/EditInputItem";
import {
  complianceEditForms,
  MONTH_NAMES_SHORT,
} from "../../../../consts/Constants";
import Button from "../../../UI/Button/Button";
import styles from "./TaxComplianceForm.module.css";
import editIcon from "/assets/common/EditPen_Blue.svg";
import OverlayRegular from "../../../UI/Modal/OverlayRegular";
import ButtonCustom from "../../../UI/Button/ButtonCustom";
import EditFilingPeriod from "./EditFilingPeriod";
import { convertISODateToStr } from "../../../../utils/common-utils";
import arrowRightWhite from "/assets/common/ArrowRight_White.svg";
import SubscriptionInputSelector from "../ui-subscriptions/SubscriptionInputSelector";
import { useCreateComplianceSubscriptionMutation } from "../apis/subscriptionsApi-slice";
import React from "react";
import {
  URL__STRIPE_SUCCESS_REDIRECT,
  URL__STRIPE_FAILURE_REDIRECT,
} from "../../../../consts/APIConstants";
import {
  setStripeRedirectUrls,
  removeStripeRedirectUrls,
} from "../../../../utils/authentication";
import {
  useAddCartItemMutation,
  useLazyGetAvailableCartEntityListQuery,
} from "../../Cart/apis/cartApi-slice";
import { absolutePaths } from "../../../../consts/PathConstants";
import MessageDialog from "../../../UI/Modal/MessageDialog";
import { BTN__RETRY, BTN__CLOSE } from "../../../../consts/TextConstants";

const TaxComplianceForm = ({ compliance_forms, title, onClose }) => {
  const { selectedCompliance } = useSelector((state) => state.subscriptions);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [taxYear, setTaxYear] = useState(""); // To store the selected tax year
  const [showEditFilingPeriod, setShowEditFilingPeriod] = useState(false); // Track which field is being edited
  const [fromDate, setFromDate] = useState(null); // Separate state for "From" date
  const [toDate, setToDate] = useState(null); // Separate state for "To" date
  const [dynamicInputValues, setDynamicInputValues] = useState({});
  const [message, setMessage] = useState({ show: false });

  const [showEntityError, setShowEntityError] = useState(false);
  const [showTaxYearError, setShowTaxYearError] = useState(false);
  const [showFilingPeriodError, setShowFilingPeriodError] = useState(false);
  const [showDynamicInputError, setShowDynamicInputError] = useState(false);

  const { entities } = useSelector((state) => state.entity);

  const { isError: isEntitiesError } = useGetEntitiesQuery();
  const [addCartItem, { isLoading: loadingAddCartItem }] =
    useAddCartItemMutation();

  const [createComplianceSubscription, { isLoading: loadingPayment }] =
    useCreateComplianceSubscriptionMutation();

  const [getAvailableCartEntityList, { isLoading: loadingCartEntityList }] =
    useLazyGetAvailableCartEntityListQuery();

  const handleEntityChange = (entity) => {
    setSelectedEntity(entity);
    setTaxYear("");
    setFromDate(null);
    setToDate(null);
    setDynamicInputValues({});
    setShowEntityError(false);
  };

  const entityOptions = useMemo(() => {
    if (!entities || entities.length === 0) return {};

    const isNonUSEntity = selectedCompliance?.entityInputCountry === "non_us";
    const countryFilter = isNonUSEntity
      ? (entity) => entity.country !== "United States"
      : (entity) => entity.country === "United States";

    return entities.filter(countryFilter)?.reduce((acc, entity) => {
      acc[entity.entityName] = entity.entityId;
      return acc;
    }, {});
  }, [entities]);

  const incorporationYear = useMemo(
    () =>
      selectedEntity
        ? new Date(
            entities.find(
              (entity) => entity.entityId === entityOptions[selectedEntity]
            )?.incorporationDate
          ).getFullYear()
        : null,
    [entities, entityOptions, selectedEntity]
  );
  const currentYear = new Date().getFullYear();

  const taxYears = useMemo(() => {
    if (incorporationYear) {
      const currentMonth = new Date().getMonth();
      const compYear =
        currentMonth < 8 && incorporationYear < currentYear
          ? currentYear - 1
          : currentYear;

      return Array.from({ length: compYear - incorporationYear + 1 }, (_, i) =>
        (compYear - i).toString()
      );
    }
    return [];
  }, [incorporationYear, currentYear]);

  const handleYearSelect = (selectedYear) => {
    setTaxYear(selectedYear);
    setShowTaxYearError(false);
  };

  const handleEditTaxPeriod = () => {
    if (taxYear) {
      setShowEditFilingPeriod(true);
    }
  };

  const handleSubmit = (data) => {
    setFromDate(data.fromDate);
    setToDate(data.toDate);
    setShowEditFilingPeriod(false);
    setShowFilingPeriodError(false);
  };

  const handleInputChange = (fieldName, value) => {
    setDynamicInputValues({ ...dynamicInputValues, [fieldName]: value });
  };

  const validateForm = () => {
    let isDynamicInputError = false;
    selectedCompliance?.complianceInputs?.forEach((input) => {
      if (!dynamicInputValues[input.fieldName]) {
        isDynamicInputError = true;
      }
    });

    if (
      !selectedEntity ||
      !taxYear ||
      (selectedCompliance?.isFilingPeriodRequired && (!fromDate || !toDate)) ||
      isDynamicInputError
    ) {
      setShowEntityError(!selectedEntity);
      setShowTaxYearError(!taxYear);
      setShowFilingPeriodError(
        selectedCompliance?.isFilingPeriodRequired && (!fromDate || !toDate)
      );
      setShowDynamicInputError(isDynamicInputError);
      return false;
    }
    return true;
  };
  const handlePayment = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      let filingPeriod;
      if (selectedCompliance?.isFilingPeriodRequired) {
        const fromDay = fromDate?.getDate().toString().padStart(2, "0");
        const toDay = toDate?.getDate().toString().padStart(2, "0");
        const fromMonth = MONTH_NAMES_SHORT[fromDate?.getMonth()];
        const toMonth = MONTH_NAMES_SHORT[toDate?.getMonth()];
        filingPeriod = `${fromDay}${fromMonth}-${toDay}${toMonth}`;
      }
      const requestData = {
        product_id: selectedCompliance?.complianceId,
        success_url: URL__STRIPE_SUCCESS_REDIRECT,
        cancel_url: URL__STRIPE_FAILURE_REDIRECT,
        // coupon_code: "WELCOME20", // Optional Field
        entity_id: entityOptions[selectedEntity],
        subscription_inputs: {
          subscription_tax_year: taxYear,
          ...(selectedCompliance?.isFilingPeriodRequired
            ? { subscription_filing_period: filingPeriod }
            : {}),
          ...dynamicInputValues,
        },
      };
      setStripeRedirectUrls(absolutePaths.COMPLIANCE, absolutePaths.SHOP, {
        complianceEntity: entities.find(
          (entity) => entity.entityId === entityOptions[selectedEntity]
        ),
      });
      const response = await createComplianceSubscription(requestData).unwrap();
      const reditectUrl = response?.url;
      window.location.href = reditectUrl;
    } catch (error) {
      console.log(error);
      removeStripeRedirectUrls();
    }
  };

  const handleAddToCart = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      let filingPeriod;
      if (selectedCompliance?.isFilingPeriodRequired) {
        const fromDay = fromDate?.getDate().toString().padStart(2, "0");
        const toDay = toDate?.getDate().toString().padStart(2, "0");
        const fromMonth = MONTH_NAMES_SHORT[fromDate?.getMonth()];
        const toMonth = MONTH_NAMES_SHORT[toDate?.getMonth()];
        filingPeriod = `${fromDay}${fromMonth}-${toDay}${toMonth}`;
      }
      const requestData = {
        product_id: selectedCompliance?.complianceId,
        entity_id: entityOptions[selectedEntity],
        subscription_inputs: {
          subscription_tax_year: taxYear,
          ...(selectedCompliance?.isFilingPeriodRequired
            ? { subscription_filing_period: filingPeriod }
            : {}),
          ...dynamicInputValues,
        },
      };
      const response = await addCartItem(requestData).unwrap();
      await getAvailableCartEntityList().unwrap();
      setMessage({
        show: true,
        isError: false,
        title: "Success",
        message: "Added to cart successfully!!",
        closeOnConfirm: true,
      });
    } catch (error) {
      console.log(error);
      setMessage({
        show: true,
        isError: true,
        title: "Error!",
        message: "Failed to add to cart. Please try again.",
        closeOnConfirm: false,
      });
    }
  };

  const handleCloseMessage = () => {
    setMessage({ show: false });
    if (message.closeOnConfirm) {
      onClose();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <EditInputItem
          name="entity"
          type="dropdown"
          labelTitle="Entity"
          className={styles.entity_form}
          options={Object.keys(entityOptions)}
          value={selectedEntity}
          onSelect={handleEntityChange}
          showError={showEntityError}
          errorMessage="Please select an entity"
        />

        {selectedCompliance?.complianceInputs?.map((input) => (
          <SubscriptionInputSelector
            key={`${input.fieldName}-${input.inputType}`}
            inputDetails={input}
            entityType={
              entities.find(
                (entity) => entity.entityId === entityOptions[selectedEntity]
              )?.entityType
            }
            onChange={(value) => handleInputChange(input.fieldName, value)}
            value={dynamicInputValues[input.fieldName] || ""}
            className={styles.tax_wid}
            showError={
              showDynamicInputError && !dynamicInputValues[input.fieldName]
            }
            errorMessage="Please select a value"
          />
        ))}

        {selectedCompliance?.complianceInputs?.length == 0 && <div />}

        <EditInputItem
          name="years"
          type="dropdown"
          labelTitle="Tax Year"
          value={taxYear}
          options={taxYears}
          onSelect={handleYearSelect}
          className={styles.tax_wid}
          showError={showTaxYearError}
          errorMessage="Please select a tax year"
        />

        {selectedCompliance?.isFilingPeriodRequired && (
          <EditInputItem
            name="Filing Period"
            type="custom"
            labelTitle="Filing Period"
            className={`${styles.tax_wid} ${taxYear ? "" : styles.disabled}`}
            showError={showFilingPeriodError}
            errorMessage="Please select a filing period"
          >
            <div
              className={`${styles.tax_period} ${
                taxYear ? "" : styles.disabled
              }`}
              onClick={handleEditTaxPeriod}
            >
              {fromDate && toDate && taxYear
                ? `${convertISODateToStr(fromDate)} - ${convertISODateToStr(
                    toDate
                  )}`
                : "Start Date - End Date"}
            </div>
          </EditInputItem>
        )}

        {showEditFilingPeriod && (
          <EditFilingPeriod
            defaultFromDate={fromDate ? fromDate : new Date(taxYear, 0, 1)}
            defaultToDate={toDate ? toDate : new Date(taxYear, 11, 31)}
            taxYear={taxYear}
            incorporationDate={new Date(selectedEntity?.incorporationDate)}
            onSubmit={handleSubmit}
            onClose={() => setShowEditFilingPeriod(false)}
          />
        )}
      </div>

      <div className={styles.footer}>
        <Button
          buttonstyle="default-blue-outline"
          className={styles.btn_add_to_cart}
          onClick={handleAddToCart}
          disabled={loadingPayment}
          isLoading={loadingAddCartItem || loadingCartEntityList}
        >
          Add to cart
        </Button>
        <Button
          buttonstyle="default-blue"
          className={styles.btn__submit}
          onClick={handlePayment}
          disabled={loadingAddCartItem || loadingCartEntityList}
          isLoading={loadingPayment}
        >
          PAY
          <img src={arrowRightWhite} className={styles.right_icon} />
        </Button>
      </div>
      {message.show && (
        <MessageDialog
          isError={message?.isError}
          title={message?.title}
          message={message?.message}
          buttonTitle={message?.isError ? BTN__RETRY : BTN__CLOSE}
          onClose={handleCloseMessage}
        />
      )}
    </div>
  );
};

export default TaxComplianceForm;
