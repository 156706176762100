import { useState, useEffect } from "react";
import styles from "./CouponCode.module.css";
import promoIcon from "/assets/compliance/Promo.svg";
import promoRemoveIcon from "/assets/Close.svg";
import Button from "../../UI/Button/Button";
import { useSetCouponCodeMutation } from "./apis/couponCodeApi-slice";
import MessageDialog from "../../UI/Modal/MessageDialog";

const CouponCode = ({ onChange, actualPrice }) => {
  const [promoCode, setPromoCode] = useState("");
  const [promoApplied, setPromoApplied] = useState(false);
  const [error, setError] = useState({ isError: false });

  const [setCouponCode, { isLoading: isLoadingCoupon }] =
    useSetCouponCodeMutation();

  useEffect(() => {
    setPromoCode("");
    setPromoApplied(false);
    onChange({
        promoCode: "",
        isApplied: false,
        discount: 0,
        totalPayable: actualPrice,
      });
  }, [actualPrice]);

  const handlePromoChange = (e) => {
    setPromoCode(e.target.value);
  };

  const handlePromoRemove = () => {
    setPromoApplied(false);
    onChange({
      promoCode: "",
      isApplied: false,
      discount: 0,
      totalPayable: actualPrice,
    });
  };

  const handlePromoApply = async () => {
    try {
      const payload = {
        coupon_code: promoCode,
        total_cart_amount: actualPrice,
      };
      const response = await setCouponCode(payload).unwrap();
      if (response?.total_discounted_amount) {
        setPromoApplied(true);

        onChange({
          promoCode: promoCode,
          isApplied: true,
          discount: response?.total_discounted_amount,
          totalPayable: response?.total_amount_after_discount,
        });
      }
    } catch (error) {
        setError({
          isError: true,
          title: "Promo code error",
          message: error?.data?.message,
        });
    }
  };

  return (
    <div className={styles.promo}>
      <div className={styles.input__wrapper}>
        <input
          type="text"
          placeholder="Promo Code"
          className={styles.input__promo}
          value={promoCode}
          onChange={handlePromoChange}
          disabled={promoApplied}
        />
        <Button
          className={`${styles.btn__remove} ${
            promoApplied ? styles.applied : ""
          }`}
          disabled={!promoApplied}
          onClick={handlePromoRemove}
        >
          <img
            src={promoApplied ? promoRemoveIcon : promoIcon}
            alt=""
            className={styles.promo__icon}
          />
        </Button>
      </div>

      <Button
        className={`${styles.btn__apply} ${promoApplied ? styles.applied : ""}`}
        disabled={promoApplied || promoCode?.length === 0}
        // isLoading={isLoadingCoupon}
        onClick={handlePromoApply}
      >
        {promoApplied ? "Applied" : "Apply"}
      </Button>
      {error.isError && (
        <MessageDialog
          title={error.title}
          message={error.message}
          buttonTitle="Retry"
          onClose={() => setError({ isError: false })}
        />
      )}
    </div>
  );
};

export default CouponCode;
