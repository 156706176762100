import { apiSlice } from "../../../../store/api-slice";
import {
  METHOD_GET,
  METHOD_POST,
  URL__GET_CART_ITEMS,
  URL__ADD_CART_ITEM,
  URL__REMOVE_CART_ITEM,
  URL__CHECKOUT_CART,
  URL__GET_AVAILABLE_CART_ENTITY_LIST
} from "../../../../consts/APIConstants";
import { cartActions } from "../reducers/cart-slice";

const cartApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCartData: builder.query({
      query: (args) => ({
        url: URL__GET_CART_ITEMS,
        method: METHOD_GET,
        params: args,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(cartActions.setCartItems(data));

        } catch (error) {
          console.error("Error fetching cart data:", error);
          dispatch(cartActions.setCartItems([]));
        }
      },
    }),

  
    addCartItem: builder.mutation({
      query: (data) => {
        return {
          url: URL__ADD_CART_ITEM,
          method: METHOD_POST,
          body: data,
        };
      },
    }),

    removeCartItem: builder.mutation({
      query: (args) => {
        return {
          url: URL__REMOVE_CART_ITEM,
          method: METHOD_POST,
          params: args,
        };
      },
    }),

    checkoutCart: builder.mutation({
      query: (data) => {
        return {
          url: URL__CHECKOUT_CART,
          method: METHOD_POST,
          body: data,
        };
      },
    }),

    getAvailableCartEntityList: builder.query({
      query: () => ({
        url: URL__GET_AVAILABLE_CART_ENTITY_LIST,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(cartActions.setCartPendingList(data));
        } catch (error) {
          console.error("Error fetching cart pending list:", error);
        }
      },
    }),
  }),
});

export const {
  useGetCartDataQuery,
  useLazyGetCartDataQuery,
  useAddCartItemMutation,
  useRemoveCartItemMutation,
  useCheckoutCartMutation,
  useGetAvailableCartEntityListQuery,
  useLazyGetAvailableCartEntityListQuery,
} = cartApiSlice;
