import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useGetAllServicesQuery } from "./apis/subscriptionsServicesApi-slice";
import SubscriptionCard from "./ui-subscriptions/SubscriptionCard";
import styles from "./GetServicesListPage.module.css";
import { DEFAULT_PAGE_SIZE_MAX } from "../../../consts/AppConstants";
import LoadingComponent from "../../UI/LoadingIndicator/LoadingComponent";
import ErrorComponent from "../../UI/Error/ErrorComponent";
import AddServiceOverlay from "./AddService/AddServiceOverlay";

const GetServicesListPage = ({ activeTab, searchValue }) => {
  const [showServiceOverlay, setShowServiceOverlay] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  
  const { serviceList } = useSelector((state) => state.subscriptionsServices);

  // Fetch service data
  const { error, isLoading } = useGetAllServicesQuery({
    page_size: DEFAULT_PAGE_SIZE_MAX,
  });

  const handleClick = (serviceId) => {
    setSelectedServiceId(serviceId);
    setShowServiceOverlay(true);
  };

  const handleClose = () => {
    setShowServiceOverlay(false);
  };

  // Filter services based on search value
  const filteredServices = useMemo(() => {
    return serviceList.filter(
      (service) =>
        service.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        service.shortDescription
          .toLowerCase()
          .includes(searchValue.toLowerCase())
    );
  }, [serviceList, searchValue]);

  let content = null;
  if (isLoading) content = <LoadingComponent />;
  else if (error) content = <ErrorComponent errorMessage={error.message} />;
  else if (filteredServices.length === 0)
    content = <p>No services available</p>;
  else
    content = (
      <>
        {filteredServices.map((service) => (
          <SubscriptionCard
            key={service.serviceId}
            subscription={service}
            activeTab={activeTab}
            onClick={() => handleClick(service.serviceId)}
          />
        ))}
      </>
    );

  return (
    <div className={styles.container}>
      {content}
      {showServiceOverlay && (
        <AddServiceOverlay
          onClose={handleClose}
          serviceId={selectedServiceId}
        />
      )}
    </div>
  );
};

export default GetServicesListPage;
