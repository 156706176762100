import {
  URL__GET_SERVICE_BY_ID,
  METHOD_GET,
  URL__GET_ALL_SERVICES,
  URL__POST_SERVICES,
  URL__CREATE_QUOTE_SERVICE,
  METHOD_POST,
} from "../../../../consts/APIConstants";
import { apiSlice } from "../../../../store/api-slice";
import { subscriptionsServicesActions } from "../reducers/subscriptionsServices-slice";

// Define the API slice to interact with services data
export const subscriptionsServicesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServiceById: builder.query({
      query: (args) => ({
        url: URL__GET_SERVICE_BY_ID,
        method: METHOD_GET,
        params: args,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(subscriptionsServicesActions.setSelectedService(data));
        } catch (error) {
          dispatch(subscriptionsServicesActions.setSelectedService(null)); // Reset in case of error
        }
      },
    }),
    getAllServices: builder.query({
      query: (args) => ({
        url: URL__GET_ALL_SERVICES,
        method: METHOD_GET,
        headers: { Accept: "*/*", "Content-Type": "application/json" },
        params: args,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(subscriptionsServicesActions.setServiceList(data));
        } catch (error) {
          dispatch(subscriptionsServicesActions.setServiceList([]));
          console.error("Error fetching services:", error);
        }
      },
    }),
    createServiceSubscription: builder.mutation({
      query: (data) => ({
        url: URL__POST_SERVICES,
        method: METHOD_POST,
        body: data,
      }),
    }),
    createQuoteService: builder.mutation({
      query: (data) => ({
        url: URL__CREATE_QUOTE_SERVICE,
        method: METHOD_POST,
        body: data,
      }),
    }),
  }),
});

// Export the generated hook for fetching service details
export const {
  useGetServiceByIdQuery,
  useGetAllServicesQuery,
  useCreateServiceSubscriptionMutation,
  useCreateQuoteServiceMutation,
} = subscriptionsServicesApiSlice;
