import styles from "./SubscriptionInputSelector.module.css";
import EditInputItem from "../../Edit/EditInputItem";
import { isArray } from "lodash";
import { getFilteredUSStatesList } from "../../../../utils/listUSStates";
import { getCountriesList } from "../../../../utils/common-utils";
import cancelIcon from "/assets/common/Close_Blue.svg";
import Button from "../../../UI/Button/Button";
import { ERROR_MSG__NAME } from "../../../../consts/ErrorMessages";
import { LBL__NAME } from "../../../../consts/TextConstants";
const countriesList = getCountriesList();

const SubscriptionInputSelector = ({
  inputDetails,
  entityType,
  onChange,
  value,
  trcYears,
  className,
  showError,
  errorMessage,
}) => {
  switch (inputDetails?.inputType) {
    case "drop_down":
      if (
        inputDetails?.fieldValue === "state_list" ||
        inputDetails?.fieldValue === "country_list"
      ) {
        const isCountryList = inputDetails?.fieldValue === "country_list";
        const suggestions = isCountryList ? countriesList : null;
        const filteredListFunc = isCountryList ? null : getFilteredUSStatesList;
        const customFilter = isCountryList ? false : true;

        const handleChange = (item) => {
          onChange(isCountryList ? item?.value : item?.key);
        };
        return (
          <EditInputItem
            key={`${inputDetails?.fieldName}-${inputDetails?.inputType}`}
            name={inputDetails?.fieldName}
            type="autocomplete"
            labelTitle={inputDetails?.label}
            value={value || ""}
            defaultValue={value}
            customFilter={customFilter}
            filteredListFunc={filteredListFunc}
            suggestions={suggestions}
            onChange={handleChange}
            showError={showError}
            errorMessage={errorMessage}
          />
        );
      }
      let options = isArray(inputDetails?.options) ? inputDetails?.options : [];

      if (inputDetails?.fieldValue === "irs_tax_classification") {
        options = inputDetails?.options[0][entityType] || [];
      }
      if (inputDetails?.fieldValue === "trc_years") {
        options = trcYears;
      }

      return (
        <EditInputItem
          key={`${inputDetails?.fieldName}-${inputDetails?.inputType}`}
          name={inputDetails?.fieldName}
          type="dropdown"
          labelTitle={inputDetails?.label}
          value={value}
          options={options}
          onSelect={onChange}
          className={`${styles.input_dropdown} ${className}`}
          showError={showError}
          errorMessage={errorMessage}
        />
      );

    case "multi_select_drop_down":
      let multiSelectOptions = isArray(inputDetails?.fieldValue)
        ? inputDetails?.fieldValue
        : [];
      if (inputDetails?.fieldValue === "trc_years") {
        multiSelectOptions = trcYears;
      }

      const filteredOptions = multiSelectOptions.filter(
        (option) => !value?.includes(option)
      );

      const handleRemoveOption = (item) => {
        const newSelectedOptions = value.filter((newItem) => newItem !== item);
        onChange(newSelectedOptions.length > 0 ? newSelectedOptions : null);
      };

      return (
        <>
          <EditInputItem
            key={`${inputDetails?.fieldName}-${inputDetails?.inputType}`}
            name={inputDetails?.fieldName}
            type="dropdown"
            labelTitle={inputDetails?.label}
            value={""}
            options={filteredOptions}
            onSelect={(item) => onChange([...value, item])}
            className={`${styles.input_dropdown} ${className}`}
            showError={showError}
            errorMessage={errorMessage}
          />
          <div className={styles.list_multi_select_options}>
            {value &&
              value?.map((item) => (
                <div key={item} className={styles.multi_select_option}>
                  {item}
                  <Button
                    onClick={() => handleRemoveOption(item)}
                    className={styles.cancel_button}
                  >
                    <img src={cancelIcon} alt="" />
                  </Button>
                </div>
              ))}
          </div>
        </>
      );
    case "textbox":
      return (
        <EditInputItem
          key={`${inputDetails?.fieldName}-${inputDetails?.inputType}`}
          name={inputDetails?.fieldName}
          labelTitle={inputDetails?.label}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          showError={showError}
          errorMessage={`Please enter ${inputDetails?.label}`}
        />
      );

    case "date":
      return (
        <EditInputItem
          key={`${inputDetails?.fieldName}-${inputDetails?.inputType}`}
          name={inputDetails?.fieldName}
          type="datepicker"
          labelTitle={inputDetails?.label}
          onChange={onChange}
          value={value}
          showError={showError}
          errorMessage={`Please select date`}
          endDate={new Date()}
        />
      );
    default:
      return null;
  }
};

export default SubscriptionInputSelector;
