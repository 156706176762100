import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useGetAllComplianceListQuery } from "./apis/subscriptionsApi-slice";
import SubscriptionCard from "./ui-subscriptions/SubscriptionCard";
import styles from "./TaxComplianceListPage.module.css";
import { DEFAULT_PAGE_SIZE_MAX } from "../../../consts/AppConstants";
import AddTaxComplianceOverlay from "./AddCompliance/AddTaxComplianceOverlay";

const TaxComplianceListPage = ({ activeTab, searchValue }) => {
  const [showComplianceOverlay, setShowComplianceOverlay] = useState(null);
  const [selectedCompliance, setSelectedCompliance] = useState(null);

  const { complianceList } = useSelector((state) => state.subscriptions);

  // Fetching services data using a custom hook (or using passed `complianceData` prop)
  const { isLoading } = useGetAllComplianceListQuery({
    page_size: DEFAULT_PAGE_SIZE_MAX,
  });

  // Filter the compliances based on the search value dynamically
  const filteredCompliances = useMemo(
    () =>
      complianceList.filter(
        (compliance) =>
          compliance.name
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase()) ||
          compliance.complianceForms
            ?.toLowerCase()
            .includes(searchValue?.toLowerCase())
      ),
    [searchValue, complianceList]
  );

  const handleClick = (compliance) => {
    setSelectedCompliance(compliance);
    setShowComplianceOverlay(true);
  };

  const handleClose = () => {
    setShowComplianceOverlay(false);
  };

  // Render the filtered compliances dynamically
  return (
    <div className={styles.container}>
      {filteredCompliances && filteredCompliances.length > 0 ? (
        filteredCompliances.map((compliance, index) => (
          <SubscriptionCard
            key={`${compliance.complianceId}-${index}`}
            subscription={compliance}
            activeTab={activeTab}
            onClick={() => handleClick(compliance)}
          />
        ))
      ) : (
        <p>No services available</p>
      )}

      {showComplianceOverlay && (
        <AddTaxComplianceOverlay
          onClose={handleClose}
          shortDescription={selectedCompliance?.shortDescription}
          complianceId={selectedCompliance?.complianceId}
        />
      )}
    </div>
  );
};

export default TaxComplianceListPage;
