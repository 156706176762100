import { createSlice } from "@reduxjs/toolkit";
import { complianceFormMappings } from "../../../../consts/Constants";
import { isArray } from "lodash";

const initialState = {
  complianceList: [],
  incomeTaxCompliances: [],
  servicesList: [],
  selectedCompliance: null,
};

const getShortDescription = (complianceForm) => {
  if (complianceFormMappings[complianceForm]) {
    return complianceFormMappings[complianceForm]; // Use mapped value for known forms
  }
  // Handle dynamic matching for any 4-digit form number like "Form 5471"
  const regex = /\b\d{4}\b/;
  const match = complianceForm.match(regex);
  if (match) {
    return `Form ${match[0]}`;
  }
  return complianceForm;
};

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: initialState,
  reducers: {
    setComplianceList: (state, action) => {
      const { incomeTaxCompliances, otherCompliances } =
        action.payload?.data || {};

      let combinedIncomeTaxCompliance = {};

      if (incomeTaxCompliances?.length > 0) {
        let shortDescription = "Form ";
        incomeTaxCompliances?.forEach((compliance) => {
          shortDescription += compliance.compliance_forms + " / ";
        });
        shortDescription = shortDescription.slice(0, -2);

        combinedIncomeTaxCompliance = {
          shortDescription,
          name: incomeTaxCompliances[0].compliance_name,
          complianceForm: incomeTaxCompliances[0].compliance_forms,
          complianceId: incomeTaxCompliances[0].compliance_id,
          complianceType: incomeTaxCompliances[0].compliance_type,
          compliancePrice: incomeTaxCompliances[0].compliance_price,
        };
      }

      let otherCompliancesList = [];

      otherCompliances?.forEach((compliance) => {
        let complianceForm;
        let shortDescription;
        if (isArray(compliance.compliance_forms)) {
          shortDescription = compliance.compliance_forms.join(" / ");
          complianceForm = compliance.compliance_forms[0];
        } else {
          shortDescription = getShortDescription(compliance.compliance_forms);
          complianceForm = compliance.compliance_forms;
        }
        otherCompliancesList.push({
          shortDescription,
          name: compliance.compliance_name,
          complianceForm,
          complianceId: compliance.compliance_id,
          complianceType: compliance.compliance_type,
          price: compliance.compliance_price,
        });
      });

      state.complianceList = [
        combinedIncomeTaxCompliance,
        ...otherCompliancesList,
      ];
    },
    setServicesList: (state, action) => {
      state.servicesList = action.payload;
    },
    setSelectedCompliance: (state, action) => {
      const complianceData = action.payload?.data;

      let complianceInputs = [];
      complianceData?.compliance_inputs?.inputs?.forEach((input) => {
        complianceInputs.push({
          label: input.label_name || input.field_name,
          fieldName: input.field_name,
          inputType: input.input_type,
          fieldValue: input.value,
          options: input.options,
        });
      });

      state.selectedCompliance = {
        complianceId: complianceData.compliance_id,
        complianceName: complianceData.compliance_name,
        complianceForm: complianceData.compliance_forms,
        price: complianceData.compliance_price,
        detailedDescription: complianceData.compliance_detailed_description,
        complianceInputs: complianceInputs,
        entityInputCountry:
          complianceData?.compliance_inputs?.isUSEntity ? "us" : "non_us",
        isFilingPeriodRequired:
          complianceData?.compliance_inputs?.isFilingPeriodRequired === false
            ? false
            : true,
      };
    },
    clearSelectedCompliance: (state) => {
      state.selectedCompliance = null;
    },
  },
});

export const subscriptionsActions = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;
