import { useEffect, useState } from "react";
import styles from "./EditAddressInputs.module.css";
import useInput from "../../../hooks/use-input";
import EditInputItem from "./EditInputItem";
import { isNotEmpty, isNumeric, isZipCode } from "../../../utils/validations";
import {
  combineAddressString,
  getCountriesList,
} from "../../../utils/common-utils";
import {
  LBL__BUSINESS_ADDRESS,
  LBL__CITY,
  LBL__COUNTRY,
  LBL__STATE,
  LBL__STREET_ADDRESS,
  LBL__ZIP_CODE,
} from "../../../consts/TextConstants";
import {
  ERROR_MSG__STREET,
  ERROR_MSG__CITY,
  ERROR_MSG__STATE,
  ERROR_MSG__ZIP_CODE,
  ERROR_MSG__COUNTRY,
} from "../../../consts/ErrorMessages";

const countryList = getCountriesList();

const EditAddressInputs = ({
  children,
  onChange,
  combineAddress = true,
  defaultValue,
  name,
  showGroupTitle = true,
  groupTitle,
  showInAddressFormat = true,
  showCountry = true,
  showError = null,
  showCustomItem = false,
  shouldUpdateToDefaultValue = null,
  ...props
}) => {
  const [country, setCountry] = useState(
    props.lockedCountry || defaultValue?.country
  );
  const [showCountryError, setShowCountryError] = useState(false);

  const {
    value: street,
    isValid: isStreetAddressValid,
    showError: showStreetAddressError,
    handleChange: handleStreetAddressChange,
    handleBlur: handleStreetAddressBlur,
    handleFocus: handleStreetAddressFocus,
    setShowError: setShowStreetAddressError,
    reset: resetStreet,
  } = useInput(isNotEmpty, defaultValue?.street);

  const {
    value: city,
    isValid: isCityValid,
    showError: showCityError,
    handleChange: handleCityChange,
    handleBlur: handleCityBlur,
    handleFocus: handleCityFocus,
    setShowError: setShowCityError,
    reset: resetCity,
  } = useInput(isNotEmpty, defaultValue?.city);

  const {
    value: state,
    isValid: isStateValid,
    showError: showStateError,
    handleChange: handleStateChange,
    handleBlur: handleStateBlur,
    handleFocus: handleStateFocus,
    setShowError: setShowStateError,
    reset: resetState,
  } = useInput(isNotEmpty, props.lockedState || defaultValue?.state);

  const {
    value: zipCode,
    isValid: isZipCodeValid,
    showError: showZipCodeError,
    handleChange: handleZipCodeChange,
    handleBlur: handleZipCodeBlur,
    handleFocus: handleZipCodeFocus,
    setShowError: setShowZipCodeError,
    reset: resetZipCode,
  } = useInput(isZipCode, defaultValue?.zipCode);

  useEffect(() => {
    // const address = `${street}, ${city}, ${state}, ${country} - ${zipCode}`;

    const address = combineAddressString(street, city, state, zipCode, country);
    const addressParams = { street, city, state, country, zipCode };
    const isValid =
      isStreetAddressValid &&
      isCityValid &&
      isStateValid &&
      isZipCodeValid &&
      country;

    onChange({ address, isValid, addressParams });
  }, [street, city, state, country, zipCode]);

  useEffect(() => {
    if (showError !== null) {
      setShowStreetAddressError(showError && !isStreetAddressValid);
      setShowCityError(showError && !isCityValid);
      setShowStateError(showError && !isStateValid);
      setShowZipCodeError(showError && !isZipCodeValid);
      setShowCountryError(showError && !country);
    }
  }, [showError]);

  useEffect(() => {
    if (shouldUpdateToDefaultValue !== null) {
      resetStreet(shouldUpdateToDefaultValue ? defaultValue?.street : "");
      resetCity(shouldUpdateToDefaultValue ? defaultValue?.city : "");
      resetState(shouldUpdateToDefaultValue ? defaultValue?.state : "");
      resetZipCode(shouldUpdateToDefaultValue ? defaultValue?.zipCode : "");
      setCountry(shouldUpdateToDefaultValue ? defaultValue?.country : "");
    }
  }, [shouldUpdateToDefaultValue]);

  return (
    <>
      {showGroupTitle && (
        <>
          <EditInputItem
            name={`${name}-businessAddress`}
            type="label"
            labelTitle={groupTitle || LBL__BUSINESS_ADDRESS}
          />
          <div></div>
        </>
      )}

      <EditInputItem
        name={`${name}-street`}
        labelTitle={LBL__STREET_ADDRESS}
        onChange={handleStreetAddressChange}
        onBlur={handleStreetAddressBlur}
        onFocus={handleStreetAddressFocus}
        value={street}
        showError={showStreetAddressError}
        errorMessage={ERROR_MSG__STREET}
        disabled={shouldUpdateToDefaultValue}
      />

      {showInAddressFormat && (
        <div className={styles.custom__item__container}>
          {showCustomItem && children}
        </div>
      )}

      <EditInputItem
        name={`${name}-city`}
        labelTitle={LBL__CITY}
        onChange={handleCityChange}
        onBlur={handleCityBlur}
        onFocus={handleCityFocus}
        value={city}
        showError={showCityError}
        errorMessage={ERROR_MSG__CITY}
        disabled={shouldUpdateToDefaultValue}
      />

      <EditInputItem
        name={`${name}-state`}
        labelTitle={LBL__STATE}
        onChange={handleStateChange}
        onBlur={handleStateBlur}
        onFocus={handleStateFocus}
        value={state}
        disabled={props.lockedState != null || shouldUpdateToDefaultValue}
        showError={showStateError}
        errorMessage={ERROR_MSG__STATE}
      />

      <EditInputItem
        name={`${name}-zipCode`}
        labelTitle={LBL__ZIP_CODE}
        onChange={handleZipCodeChange}
        onBlur={handleZipCodeBlur}
        onFocus={handleZipCodeFocus}
        value={zipCode}
        showError={showZipCodeError}
        errorMessage={ERROR_MSG__ZIP_CODE}
        disabled={shouldUpdateToDefaultValue}
      />

      {showCountry && (
        <EditInputItem
          name={`${name}-country`}
          type="autocomplete"
          labelTitle={LBL__COUNTRY}
          value={country || ""}
          defaultValue={props.lockedCountry || defaultValue?.country}
          onChange={(item) => {
            setCountry(item?.value);
            setShowCountryError(false);
          }}
          suggestions={countryList}
          showListOnTop={true}
          disabled={props.lockedCountry != null || shouldUpdateToDefaultValue}
          showError={showCountryError}
          errorMessage={ERROR_MSG__COUNTRY}
        />
      )}
    </>
  );
};

export default EditAddressInputs;
