import { useState, useEffect } from "react";
import styles from "./EditIssuedSharesOverlay.module.css";
import EditInputItem from "../Edit/EditInputItem";
import { ERROR_MSG__NUMERICAL_VALUES } from "../../../consts/ErrorMessages";
import Button from "../../UI/Button/Button";
import { useUpdateComplianceEntityMutation } from "../Compliance/apis/complianceApi-slice";
import {
  useUpdateEntityMutation,
  useLazyGetEntityByIdQuery,
} from "../Entities/apis/entityApi-slice";
import {
  isNumeric,
  isNotEmpty,
  isGreaterThanValue,
  isBetweenRange,
} from "../../../utils/validations";
import useInput from "../../../hooks/use-input";
import MessageDialog from "../../UI/Modal/MessageDialog";
import { convertISODateToStr } from "../../../utils/common-utils";
import OverlayRegular from "../../UI/Modal/OverlayRegular";

const EditIssuedSharesOverlay = ({
  entityType,
  entityId,
  onClose,
  toDate,
  authorizedShares,
  totalNoOfShares,
}) => {
  const [error, setError] = useState({ isError: false });
  const [inputErrorMessage, setInputErrorMessage] = useState(
    "Please enter valid issued shares"
  );

  const {
    value: issuedShares,
    isValid: isIssuedSharesValid,
    showError: showIssuedSharesError,
    handleChange: handleIssuedSharesChange,
    handleBlur: handleIssuedSharesBlur,
    handleFocus: handleIssuedSharesFocus,
    setShowError: setShowIssuedSharesError,
  } = useInput(
    (value) =>
      isNumeric(value) &&
      isNotEmpty(value) &&
      isBetweenRange(value, totalNoOfShares, authorizedShares) &&
      Number(value) > 0
  );

  const [getEntityById, { isLoading: isEntityByIdLoading }] =
    useLazyGetEntityByIdQuery();
  const [updateComplianceEntity, { isLoading }] =
    useUpdateComplianceEntityMutation();

  //   const [getForeignShareHolders, { isLoading: isForeignShareHoldersLoading }] =
  //     useLazyGetForeignShareHoldersQuery();

  useEffect(() => {
    if (Number(issuedShares) > authorizedShares) {
      setInputErrorMessage(
        "Issued shares cannot be greater than authorized shares"
      );
    } else if (Number(issuedShares) < totalNoOfShares) {
      setInputErrorMessage(
        "Issued shares cannot be lesser than total number of shares"
      );
    } else {
      setInputErrorMessage("Please enter valid issued shares");
    }
  }, [isIssuedSharesValid, showIssuedSharesError]);

  const handleSubmit = async () => {
    if (!isIssuedSharesValid) {
      setShowIssuedSharesError(true);
      return;
    }
    try {
      const data = {
        entity_id: entityId,
        issued_shares: Number(issuedShares),
      };
      const params = {
        type: "registration-entity",
      };
      // const response = await updateEntity({ params, data }).unwrap();
      const response = await updateComplianceEntity(data).unwrap();
      await getEntityById({ entity_id: entityId }).unwrap();
      onClose();
    } catch (err) {
      setError({
        isError: true,
        title: "Update error",
        message: err?.data?.message,
      });
    }
  };

  const handleCloseError = () => {
    setError({ isError: false, title: "", message: "" });
  };
  return (
    <OverlayRegular onClose={onClose}>
      <div className={styles.container}>
        {error.isError && (
          <MessageDialog
            title={error.title}
            message={error.message}
            buttonTitle="Retry"
            onClose={handleCloseError}
          />
        )}
        <div className={styles.form_inputs}>
          <EditInputItem
            name="issuedShares"
            labelTitle={`Issued Shares * ${
              toDate ? "as on " + convertISODateToStr(toDate) : ""
            }`}
            onChange={handleIssuedSharesChange}
            onBlur={handleIssuedSharesBlur}
            onFocus={handleIssuedSharesFocus}
            value={issuedShares}
            showError={showIssuedSharesError}
            errorMessage={inputErrorMessage}
          />
        </div>
        <Button
          className={styles.btn__submit}
          onClick={handleSubmit}
          buttonstyle="default-blue"
          isLoading={isLoading || isEntityByIdLoading}
        >
          Submit
        </Button>
      </div>
    </OverlayRegular>
  );
};

export default EditIssuedSharesOverlay;
